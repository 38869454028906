export default class ParametroService {
  constructor(http) {
    this._http = http
  }

  async getOneByMatriculaId(matriculaId) {
    const { data } = await this._http.get(
      `/api/parametro/matricula/${matriculaId}`,
    )
    return data
  }

  async getHistoricoByMatriculaId(matriculaId) {
    const { data } = await this._http.get(
      `/api/parametro/historico/matricula/${matriculaId}`,
    )
    return data
  }

  async salvar(parametro) {
    if (parametro.id) {
      return await this._http.put('/api/parametro', parametro)
    } else {
      return await this._http.post('/api/parametro', parametro)
    }
  }
}
