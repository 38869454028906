export default class Parametro {
  constructor(
    id = null,
    porcentagemMargem6 = null,
    porcentagemMargem4 = null,
    porcentagemMargem3 = null,
    porcentagemMargem5 = null,
    motivo = null,
    auditMetadata = null,
  ) {
    this.id = id
    this.porcentagemMargem6 = porcentagemMargem6
    this.porcentagemMargem4 = porcentagemMargem4
    this.porcentagemMargem3 = porcentagemMargem3
    this.porcentagemMargem5 = porcentagemMargem5
    this.motivo = motivo
    this.auditMetadata = auditMetadata
  }
}
