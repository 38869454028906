<template>
  <div>
    <div>
      <Card class="col-12">
        <template #content>
          <div class="p-fluid formgrid grid d-flex mt-5">
            <div class="col-12 md:col-3">
              <label for="margem6" class="font-bold uppercase">{{
                labelMargem('margem6')
              }}</label>
              <InputNumber
                id="margem6"
                v-model="store.dados.margem6"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem5" class="font-bold uppercase">{{
                labelMargem('margem5')
              }}</label>
              <InputNumber
                id="margem5"
                v-model="store.dados.margem5"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem4" class="font-bold uppercase">{{
                labelMargem('margem4')
              }}</label>
              <InputNumber
                id="margem4"
                v-model="store.dados.margem4"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem3" class="font-bold uppercase">{{
                labelMargem('margem3')
              }}</label>
              <InputNumber
                id="margem3"
                v-model="store.dados.margem3"
                mode="currency"
                currency="BRL"
                disabled />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { servidorStore } from '@/stores/servidor'
import Parametro from '@/domain/Parametro'
import ParametroService from '@/service/ParametroService.js'

export default {
  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      idMatricula: this.$route.params.id,
      parametro: new Parametro(),
    }
  },

  created() {
    this.parametroService = new ParametroService(this.$http)
  },

  mounted() {
    this.carregarParametros()
  },

  methods: {
    labelMargem(chave) {
      let label = this.store.dados.configuracoes?.find(
        (el) => el.chave === chave,
      )

      if (label) {
        if (label?.chave === 'margem6') {
          if (this.parametro?.porcentagemMargem6) {
            return `Empréstimo  ${this.parametro.porcentagemMargem6}%`
          }
        } else if (label?.chave === 'margem5') {
          if (this.parametro?.porcentagemMargem5) {
            return `Adiantamento  ${this.parametro.porcentagemMargem5}%`
          }
        } else if (label?.chave === 'margem4') {
          if (this.parametro?.porcentagemMargem4) {
            return `Cartão  ${this.parametro.porcentagemMargem4}%`
          }
        } else if (label?.chave === 'margem3') {
          if (this.parametro?.porcentagemMargem3) {
            return `Bens Duráveis  ${this.parametro.porcentagemMargem3}%`
          }
        }

        return label?.valor
      }
      return chave
    },
    carregarParametros() {
      this.parametroService
        .getOneByMatriculaId(this.idMatricula)
        .then((res) => {
          this.parametro = res
        })
    },
  },
}
</script>
